/**
 * @Description: 从共方法
 * @FileName: common.js
 * @Author: name
 * @Date: 2023-10-18 19:32:09
 * @Version: V1.0.0
 **/
export const COMMON = {
  /**
   *@Description: 对象数组筛选
   *@Date: 2023-10-18 19:33:05
   *@Params1: data 对象数组， keyword 要筛选的关键字，key 对象键
   *@Return1:
   */
  search(data, keyword, key) {
    if (keyword == "") {
      return data;
    }
    const pattern = new RegExp(keyword, "i"); // 不区分大小写
    // let res = data.filter(item => {
    //   return pattern.test(item[key]);
    // });
    let res = [];
    for (let i = 0; i < data.length; i++) {
      // if (pattern.test(data[i][key])) {
      //   res.push(data[i]);
      // }
      console.log("data[i]", data[i]);

      if (String(data[i]?.title).indexOf(keyword) != -1) {
        res.push(data[i]);
      }
    }
    console.log("res", res);

    return res;
  },
};

/**
 * 日期处理
 * @param {Object} params
 */
export function getTaskOrderNum(orderNumber) {
  let time = new Date();
  let year = time.getFullYear();
  const month = (time.getMonth() + 1).toString().padStart(2, "0");
  const date = time.getDate().toString().padStart(2, "0");
  const hours = time.getHours().toString().padStart(2, "0");
  const minute = time.getMinutes().toString().padStart(2, "0");
  const second = time.getSeconds().toString().padStart(2, "0");
  return orderNumber + year + month + date + hours + minute + second;
}
