var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"livenvr"},[_c('LivePlayer',{ref:"livePlayer",class:{
    small: !_vm.videoFlag,
    'livenvr-player': _vm.videoFlag,
    vUrl: _vm.isStatus && !_vm.videoFlag,
    vUrlDeffault: !_vm.isStatus && !_vm.videoFlag,
  },attrs:{"aspect":"fulllscreen","videoUrl":_vm.data.vUrl}}),(_vm.fpvUrl.vUrl)?_c('LivePlayer',{ref:"fpvPlayer",class:{
    vUrl: _vm.isStatus,
    vUrlDeffault: !_vm.isStatus,
    small: _vm.videoFlag,
    'livenvr-player': !_vm.videoFlag,
  },attrs:{"controls":false,"videoUrl":_vm.fpvUrl.vUrl}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }