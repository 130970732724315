<!-- 
  飞行监控 航线库
 -->
<template>
  <div class="card-container" @click="cardClickHandle">

    <div class="line line1">
      <div class="item item-left">
        <!-- 安全状态 -->
        <div v-if="false" class="f" :style="{
    color: (data && data.issafe == 1) ? '#19D864 !important' : '#FFBD36 !important',
    border: data && data.issafe == 1 ? '1px solid #19D864 !important' : '1px solid #FFBD36 !important',
  }">
          {{ data && data.issafe == 1 ? '安全' : '待确定' }}
        </div>
        <div class="n">{{ data && data.title || '暂无' }}</div>
      </div>

      <!-- 空域状态 1 可用，2 待申请，3 待审批，4 通过，5 驳回， 其他 暂无 -->
      <div class="item item-right">
        <div class="f1" v-if="data && data.status == 1">可用</div>
        <div class="f1" v-else-if="data && data.status == 2" style="color: #2ca1e2; border: 1px solid #2ca1e2">待申请</div>
        <div class="f1" v-else-if="data && data.status == 3" style="color: #ffbd36; border: 1px solid #ffbd36">待审批</div>
        <div class="f1" v-else-if="data && data.status == 4" style="color: #2bfdf1; border: 1px solid #2bfdf1">通过</div>
        <div class="f1" v-else-if="data && data.status == 5" style="color: #fb4a2d; border: 1px solid #fb4a2d">驳回</div>
        <div class="f1" v-else>暂无</div>
      </div>
    </div>

    <div class="line line2">
      <div class="item item1">
        <span class="num">{{ data && data.distance }}</span>
        <span class="name">航线长度(m)</span>
      </div>

      <div class="item item2">
        <span class="num">{{ data && data.time }}</span>
        <span class="name">预计时长(S)</span>
      </div>

      <div class="item item3">
        <span class="num">{{ data && data.pointCount }}</span>
        <span class="name">航点个数(个)</span>
      </div>

    </div>

    <div class="line line3">

      <div class="item item1">
        <img src="@/assets/img/i2.png" alt="">
        <span>{{ data && data.id }}</span>
      </div>

      <div class="item item2">
        <img src="@/assets/img/i3.png" alt="">
        <span>所属区域：{{ data && data.organizationName }}</span>
      </div>

    </div>

    <div class="line line4">

      <div class="item item1">
        <img src="@/assets/img/i1.png" alt="">
        <span>{{ data && data.addTime }}</span>
      </div>

      <div class="item item2">
        <img src="@/assets/img/i4.png" alt="">
        <span>{{ data && data.title }}</span>
      </div>
    </div>

    <div class="line line5" v-if="false">
      <div class="item">
        <el-tooltip class="item" effect="dark" content="预览" placement="top">
          <img @click="handleAirLinePreview(data)" src="@/assets/img/i14.png" alt="">
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="安全" placement="top">
          <img @click="handleAirLineChangeSafe(data)" src="@/assets/img/i16.png" alt="">
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="航线标签" placement="top">
          <img src="@/assets/img/i15.png" alt="" @click="showRouteLabel(data)">
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="删除" placement="top">
          <img @click="handleAirLineDelete(data)" src="@/assets/img/i13.png" alt="">
        </el-tooltip>
      </div>
    </div>

  </div>
</template>

<script>
import API from '@/api'
import { Utils } from '@/lib/cesium'
import Bus from '@/assets/ligature.js'
let point_index = null
let isEditting = false
let airline_entitys = []
export default {
  props: {
    data: {
      type: Object,
      default: {}
    },
    styleIndex: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      lineList: {
        id: '',
      },
      cesiumLine: null,
    }
  },
  mounted() {
    Bus.$on('info-sidebar-selected', (v) => {
      this.clear_airline_entitys()
    });
  },
  methods: {
    // 创建标签
    create_label(before, after) {
      let viewer = window.viewer
      let before_wgs84 = Utils.transformCartesian2WGS84(before)
      let after_wgs84 = Utils.transformCartesian2WGS84(after)

      let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2
      let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2
      let alt = (after_wgs84.alt + before_wgs84.alt) / 2

      let position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt + 10,
      })

      let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`

      let label_entity = viewer.entities.add({
        id: `label_${before}`,
        position: position,
        label: {
          text: text,
          scale: 0.5,
          font: 'bold 30px Microsoft YaHei',
          fillColor: Cesium.Color.fromCssColorString('#fff'),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          backgroundPadding: new Cesium.Cartesian2(10, 10),
        },
      })
      airline_entitys.push(label_entity)
    },
    // 清除绘制的航线
    clear_airline_entitys() {
      let viewer = window.viewer
      // let point_entity = viewer.entities.getById('polyline' + item.id)
      // viewer.entities.remove(point_entity)
      // console.log(airline_entitys, 'airline_entitys');
      airline_entitys.forEach((item, index) => {
        viewer.entities.remove(item)
      });
      // 强制刷新
      viewer.scene.forceRender();
    },
    // 航线预览
    async handleAirLinePreview(item) {
      // console.log(item, 'item');
      let flightCourseJson = JSON.parse(item.flightCourseJson);
      if (flightCourseJson.points.length == 0) {
        this.$message({
          type: 'warning',
          message: '暂无数据',
        });
        return;
      }

      this.cesiumLine = true;
      let viewer = window.viewer;
      this.clear_airline_entitys(item);
      if (item?.flightCourseJson) {
        let positions = []
        let center = []
        let polyline = JSON.parse(item.flightCourseJson)
        let label_arr = []
        let _this = this;
        polyline.points.forEach((item, index) => {
          positions.push(item.lon, item.lat, item.alt)
          label_arr.push(
            Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt)
          )
          let point_entity = null
          if (index == polyline.points.length - 1) {
            point_entity = viewer.entities.add({
              position: Cesium.Cartesian3.fromDegrees(
                item.lon,
                item.lat,
                item.alt
              ),
              name: 'show_airline_point',
              label: {
                text: String(item.alt) + 'm',
                scale: 0.6,
                font: 'bold 28px Microsoft YaHei',
                fillColor: Cesium.Color.fromCssColorString('#FF7F09'),
                horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                showBackground: false,
                outlineWidth: 0,
                pixelOffset: new Cesium.Cartesian2(25, -10),
              },
            })
          } else {
            point_entity = viewer.entities.add({
              position: Cesium.Cartesian3.fromDegrees(
                item.lon,
                item.lat,
                item.alt
              ),
              name: 'show_airline_point',
              point: {
                pixelSize: 20,
                color: Cesium.Color.RED,
                outlineWidth: 2,
                outlineColor: Cesium.Color.WHITE,
              },
              label: {
                text: new Cesium.CallbackProperty(() => {
                  return String(index + 1)
                }, false),
                font: 'bold 20px Microsoft YaHei',
                fillColor: Cesium.Color.fromCssColorString('white'),
                horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                showBackground: false,
                outlineWidth: 0,
                pixelOffset: new Cesium.Cartesian2(0, 0),
              },
            })
          }

          if (this.cesiumLine == true) {
            viewer.entities.remove(point_entity)
            this.cesiumLine = false
            return
          } else {
            airline_entitys.push(point_entity)
          }
          if (label_arr.length > 1) {
            let before = label_arr[label_arr.length - 2]
            let after = label_arr[label_arr.length - 1]

            _this.create_label(before, after)
          }
        })

        positions = Cesium.Cartesian3.fromDegreesArrayHeights(positions)
        let redLine = (this.viewer = viewer.entities.add({
          name: 'Red line on terrain',
          id: 'polyline' + item.id,
          positions: positions[0],
          polyline: {
            positions: new Cesium.CallbackProperty(() => {
              return positions
            }, false),
            width: 10,
            // material: Cesium.Color.RED,
            material: new Cesium.PolylineArrowMaterialProperty(
              Cesium.Color.fromCssColorString('#00A9A9')
            ),
            // clampToGround: true,
          },
        }))
        airline_entitys.push(redLine)
        viewer.flyTo(redLine)
      }
    },
    // 删除航线
    async handleAirLineDelete(item) {
      // console.log(item, 'item');
      this.$confirm(`确认要删除名称为${item.flightName}的航线吗，是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await API.AIRWAY.Delete({ id: item.id, })
        if (res) {
          this.$message({
            type: 'success',
            message: '删除成功',
          })
          this.clear_airline_entitys();

          this.$emit('deleted');

          let len = this.airway.list.length;
          let index = 0;
          for (let i = 0; i < len; i++) {
            if (this.airway.list[i].id == item.id) {
              index = i;
              break;
            }
          }
          this.airway.list.splice(index, 1)
        }
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },
    // 修改为安全航线
    async handleAirLineChangeSafe(item) {
      this.$emit("chanegSafety", item);
    },
    // 航线标签
    showRouteLabel(item) {
      this.$emit('showRouteLabel', item);
    },
    /**
     *@Description: 点击航线库的卡片选择该航线
     *@Date: 2023-10-18 16:23:12
     *@Params1: 
     *@Return1: 
    */
    cardClickHandle() {
      this.$emit('airLineSelectedHandle', this.data, this.styleIndex);
    },
  },
  destroyed() {
    this.clear_airline_entitys()
  },
}
</script>

<style lang="scss" scoped>
.card-container {
  width: 100%;
  height: 169px;
  background: #163B53;
  border-radius: 4px;
  padding: 5px 0 0 0;
  margin: 5px 0;
  cursor: pointer;


  .line {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .line1 {
    // height: 18%;
    height: 23%;
    // background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .item-left {
      .f {
        border: 1px solid #3CE171;
        padding: 2px 5px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3CE171;
      }

      .n {
        // margin-lef;
        margin-left: 5px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        // cursor: pointer;
      }
    }

    .item-right {
      display: flex;
      justify-content: flex-end;

      .f1 {
        border: 1px solid #3CE171;
        padding: 2px 5px;
        font-size: 12px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3CE171;
      }
    }
  }

  .line2 {
    // height: 32%;
    height: 37%;
    // background-color: rgb(0, 255, 106);
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      height: 90%;
      width: 26%;
      // border: 1px solid yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .num {
        font-size: 16px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #FFFFFF;
      }

      .name {
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(98, 123, 139, 1);
        margin-top: 5px;
      }
    }
  }

  .line3,
  .line4 {
    // height: 15%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .item {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 18px;
        height: 18px;
      }

      span {
        margin-left: 5px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(99, 130, 163, 1);
      }
    }
  }

  .line5 {
    height: 20%;
    // background-color: rgb(200, 255, 0);
    display: flex;
    justify-content: flex-end;

    .item {
      height: 100%;
      width: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      // background-color: #3CE171;

      img {
        height: 20px;
        width: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>