<!-- 飞控中心 -->
<template>
  <div class="uav-list-container">

    <div class="fckernelVideo_nestList list">
      <template v-if="list && list.length">
        <!-- <div class="pr30 pl10"> -->
        <div class="">
          <Item v-for="item in list" :key="item.id" :data="item" :level="1" @refresh="$emit('refresh')" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Dialog from "@/components/observe/fckernel/common/dialog";
import Item from "./item";
import UavSearch from "@/components/observe/search";

export default {
  data () {
    return {
      videoFlag: false,
    };
  },
  props: {
    containerStyle: {
      type: String | Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: { Dialog, Item, UavSearch },
  // inject: ["findList", "change_page"],
  inject: {
    findList: { value: "findList", default: null },
    change_page: { value: "change_page", default: null },
  },

  methods: {
    searchFn (data) {
      //  state 1代表机巢
      this.findList(data);
    },
    change () {
      this.videoFlag = !this.videoFlag;
      this.$emit("videoChange", this.videoFlag);
    },
  },
  mounted () { },
  provide () {
    return {
      fn: (...args) => this.$emit("fn", ...args),
    };
  },
};
</script>

<style lang="scss" scoped>
.uav-list-container {
  width: 100%;
  height: 100%;
  background-color: #0A293A;
}

.list {
  overflow-y: auto;
}

.fckernelVideo_nestList {
  // height: 36vh;
  height: 100%;
  overflow-y: auto;
}

.cpt-observe-uav_list {
  height: 100%;
  box-sizing: border-box;
}

.mountItem {
  background: rgba(25, 42, 61, 0.5);
  box-shadow: inset 0 0 38px 0 rgba(0, 219, 255, 0.71);
  border-radius: 10px;
  padding: 10px;
}

.wrjBtn {
  background: rgba(6, 23, 56, 0.6);
  border: 0 solid #00b6ff;
  box-shadow: 1px 1px 2px 0 rgba(3, 16, 50, 0.5), inset 0 0 12px 0 #00b6ff;
  border-radius: 3px;
}

.all {
  margin-top: 10px;
  text-indent: 1em;
  background-image: linear-gradient(270deg,
      rgba(12, 134, 242, 0) 0%,
      rgba(37, 151, 251, 0.62) 99%);
  width: 100px;
  color: #a6caeb;
  height: 20px;
  border-radius: 4px;
}

.jz {
  height: 100%;
  display: flex;
  align-items: center;
}

.mar {
  margin: 0 0 0 5px;
  color: #fff !important;
  font-weight: 700 !important;
}
</style>
