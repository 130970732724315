import { Loading } from 'element-ui';
export const DOM = {
  fullscreen: () => {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    }
  },

  exit_fullscreen () {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  },
  /**
 *@description: 创建加载动画
 *@date: 2023-09-28 11:33:57
 *@params1: text 加载显示文字，domId 承载动画的div
*/
  createLoading (text, domId) {
    // if (text == '正在加载数据') {
    //   text = '';
    // }
    // console.log(document.querySelector(`#${domId}`), 'document.querySelector(`#${domId}`)');
    let loadingInstance = Loading.service({
      target: document.querySelector(`#${domId}`),
      fullscreen: false,
      text: text,
      body: true,
      background: 'rgba(0, 0, 0, 0.3)',
      spinner: 'my-custom-loader'
    });
    return loadingInstance;
  }
}