<template>
  <div class="qingliu">
    <div ref="player_area"></div>
  </div>
</template>

<script>
import { dataURLToBlob, canvasToDataURL } from "@/utils/image-tool";

export default {
  name: "QingLiuPlayer",
  props: {
    data: Object,
  },
  data () {
    return {
      name: "QingLiuPlayer" + Date.now(),
    };
  },
  mounted () {
    window.qingliu = this.init;
    this.init();
  },
  beforeDestroy () {
    window.kbt_player_destroy(this.name);
  },
  methods: {
    /**
     * 初始化
     */
    init () {
      window.kbt_sdk_load().then(() => {
        let parentObj = this.$refs.player_area;
        window.kbt_player_create(
          this.name,
          this.data.vUrl,
          970,
          545,
          parentObj
        );
      });
    },
    screenShot () {
      var canvas = document.querySelector("#sdk_canvas_" + this.name);
      const image = canvasToDataURL(canvas);
      let blob = dataURLToBlob(image);
      return blob;
    },
    fullScreen () {
      var canvas = document.querySelector("#sdk_canvas_" + this.name);
      canvas.requestFullscreen();
    },
  },
};
</script>

<style scoped lang="scss">
.qingliu {
  width: 100%;
  height: 100%;
  // border: 5px solid pink;

  .player_area {
    width: 100%;
    height: 100%;
  }

  // width: 100%;
  // height: 100%;
  /deep/canvas {
    width: 100% !important;
  }
}
</style>
