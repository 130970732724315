<template>
  <div class="cpt-nest-list-item wih100">
    <div class="nest-group_box">
      <div class="group-title_box">
        <div class="title-box">
          <img class="img" src="@/assets/images/uav_item1_1.png" alt="">
          <span :title="data.name" class="title">{{ data.name }}</span>
        </div>
        <div class="line-box w210">
          <span class="cf"> {{ data.online + data.offline }} 架</span>
          <span class="healthy--un ml15 iconfont icon-basezaixian" style="color: #00F5FF;"></span>
          <span class="healthy--un ml5" style="color: #00F5FF">{{ data.online }}在线</span>
          <span>/</span>
          <span class="healthy--total" style="color: #cad8d9">{{ data.offline }}离线</span>
        </div>
      </div>
    </div>

    <div class="nest-children" :class="{ collapse: data.collapse }">
      <template v-if="data && data.nestList && data.nestList.length">
        <div class="nest-device-list">
          <div v-for="device in data.nestList" :key="`device_${device.id}`" class="nest-device-item"
            :class="{ online: device.online === 1 }">
            <!-- 最前面的选项框 -->
            <div class="title-box title-box-new">
              <el-tooltip :content="device.online === 1 ? '在线' : '离线'" placement="top" :enterable="false"></el-tooltip>

              <img src="~@/assets/img/i134.png" alt="" class="img" v-if="device.online != 1">
              <img src="~@/assets/img/i135.png" alt="" class="img" v-else>

              <span :title="device.name" class="title">{{ device.name }}</span>
              <span class="li" v-if="device.online != 1">(离线)</span>
              <span class="zai" v-else>(在线)</span>
            </div>
            <div class="icon-box">
              <el-popover v-if="device.warnList && device.warnList.length > 0" placement="right" width="400"
                trigger="hover">
                <div style="display: flex; flex-direction: column">
                  <div style="line-height: 20px; color: #fff; padding: 12px" v-for="(warn, index) in device.warnList"
                    :key="index">{{ warn.content }}</div>
                </div>
                <span slot="reference" style="margin-right: 12px" class="iconfont icon-jiankang"></span>
              </el-popover>
              <!-- <span
                class="iconfont icon-dingwei1"
              ></span>-->
              <div @click="fn('yc', device, orgName = data.orgName)" style="color:#ffffff"
                class="iconfont icon-baseshipinbofang mr10" title="视频"></div>
              <!-- @click="fn(4, device,orgName=data.orgName)" -->
            </div>
          </div>
        </div>
      </template>
      <div class="nest-child_group_box" v-if="data.child && data.child.length">
        <Item v-for="item in data.child" :data="item" :key="`device_child_${item.id}`" />
      </div>
    </div>
  </div>
</template>

<script>
// 引用bus
import Bus from "@/assets/ligature.js";
export default {
  name: "Item",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      device: {},
    };
  },
  computed: {
    curr () {
      return this.current();
    },
  },
  methods: {},
  inject: ["fn"],
};
</script>

<style lang="scss" scoped>
.cpt-nest-list-item {
  .nest-group_box {
    width: 100%;
    width: 100%;
    background-color: #1C455F;

    .group-title_box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      height: 40px;
      padding: 0 10px;

      .title-box {
        width: calc(100% - 80px);
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        // align-items: center;
        // justify-content: center;
        // border: 1px solid yellow;
        box-sizing: border-box;

        .iconfont {
          color: #ccedff;
          transform: rotate(90deg);
          transition: 0.3s;
          cursor: pointer;

          &.collapse {
            transform: rotate(0deg);
          }
        }

        .title {
          font-family: Microsoft YaHei;
          font-size: 14px;
          color: #ccedff;
          font-weight: 400;
          margin-left: 5px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .title-box-new {
        display: flex !important;
      }

      .line-box {
        width: 80px;
        flex-shrink: 0;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: #ccedff;
        line-height: 19px;
        font-weight: 400;
        text-align: right;
        margin: 0 0 0 0;
      }
    }
  }

  .nest-children {
    overflow: hidden;
    background-color: #0A293A;
    box-sizing: border-box;

    &.collapse {
      height: 0;
    }

    .nest-device-list {
      .nest-device-item {
        height: 30px;
        line-height: 30px;
        box-sizing: border-box;
        padding-left: 13px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .iconfont {
          font-size: 22px;
          color: #9baaac;
        }

        &.online {
          .title-box {
            .iconfont {
              color: #fff;
            }


          }
        }

        .title-box {
          height: 100%;
          white-space: nowrap;

          display: flex;

          ::v-deep .el-checkbox {
            &.is-checked {
              .el-checkbox__input {
                .el-checkbox__inner {
                  border-color: #08c2d1;

                  &::after {
                    border-color: #08c2d1;
                  }
                }
              }
            }

            .el-checkbox__input {
              .el-checkbox__inner {
                border-radius: 0;
                width: 18px;
                height: 18px;
                background: #000000;
                // border-color: #08c2d1;
                border-color: #fff;
                box-sizing: border-box;

                &::after {
                  height: 10px;
                  left: 5px;
                  top: 1px;
                  width: 5px;
                }
              }
            }
          }

          .iconfont {
            font-size: 20px;
            color: #ccedff;
          }

          .title {
            font-family: MicrosoftYaHei;
            font-size: 13px;
            color: #9baaac;
            font-weight: 400;
            margin-left: 8px;
          }
        }

        .icon-box {
          .icon-jiankang {
            font-weight: bold;
            color: #d54a15;

            &.healthy {
              color: #15d589;
            }
          }
        }
      }
    }

    .nest-child_group_box {
      box-sizing: border-box;
      padding-left: 20px;
    }
  }
}

.li {


  font-size: 16px;
  color: #9baaac;
  font-weight: 400;
}

.zai {
  font-size: 13px;
  color: #00F5FF;
  font-weight: 400;
}

/deep/ .el-checkbox__inner {
  background: transparent !important;
}
</style>

<style>
.el-popover .popper__arrow::after {
  border-bottom-color: transparent !important;
}
</style>